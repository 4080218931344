import Api from '@/services/Index';

const admin = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/minor/applications/admin/index',config);
}
const adminDelete = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('minor/applications/admin/delete/' + id);
}

const studentForm = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/minor/applications/student-form');
}

const getAll = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/minor/applications');
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/minor/applications/' + id);
}

const programs = async (facultyCode) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/minor/applications/programs/' + facultyCode);
}

const store = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/minor/applications');
}

const del = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.delete('/minor/applications/' + id);
}

const downloadAsExcel = (config) =>{
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/minor/applications/excel-export',{...config,responseType:'arraybuffer'});
}


export default {
    admin,
    adminDelete,
    studentForm,
    getAll,
    get,
    programs,
    store,
    del,
    downloadAsExcel
}
